<template>
  <div class="activeTask-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog :title="givesTitle" :visible.sync="dialogVisibleGives" :close-on-click-modal="false" width="65%">
      <div class="deviceDetails-table">
        <el-table :data="GivesDataLt" height="500" style="width: 100%">
          <el-table-column prop="name" label="商品名称" align="center"></el-table-column>
          <el-table-column prop="code" label="商品编号" align="center"></el-table-column>
          <el-table-column prop="typeName" label="商品品类" align="center"></el-table-column>
          <el-table-column prop="quantity" label="赠品数量" align="center"></el-table-column>
          <el-table-column prop="costPrice" label="成本价" align="center"></el-table-column>
          <el-table-column prop="sellPrice" label="销售价" align="center"></el-table-column>
          <el-table-column prop="merchantName" label="发布商家" align="center"></el-table-column>
          <el-table-column prop="supplierName" label="供应商名称" align="center"></el-table-column>
          <el-table-column prop="detail" label="商品描述" align="center"></el-table-column>
          <el-table-column prop="pic" label="商品图片" align="center">
            <template slot-scope="scope">
              <img :src="scope.row.pic" style="height:100px;" alt="">
            </template>
          </el-table-column>
          <el-table-column prop="auditState" label="商品状态" align="center"></el-table-column>
        </el-table>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="close()">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import cabinetUtil from "@utils/cabinetUtil.js"
export default {
  data() {
    let _this = this;
    return {
      givesTitle: '赠送商品详情',
      dialogVisibleGives: false,
      GivesDataLt: [],
      opt: {
        title: "活动任务",
        search: [
          {
            key: "merchantId",
            label: "商家名称",
            type: "remoteSearchById",
            opt: {
              list: [],
              change(data) {
                _this.getStoreList(data)
              }
            }
          },
          {
            key: "groupId",
            label: "送货门店",
            type: "remoteSearchById",
            opt: {
              list: []
            }
          },
          {
            key: "orderNo",
            label: "活动订单号",
          },
          {
            key:"userName",
            label:"消费者",
          },
          {
            key: "date",
            label: "提交时间",
            type: "data-range"
          },
          {
            key: "state",
            label: "订单状态",
            type: 'select',
            opt: {
              list: [
                {
                value: 0,
                label: "未送货",
              },
              {
                value: 1,
                label: "已送货",
              }
              ]
            }
          }
        ],
        columns: [{
          label: "活动订单号",
          key: "orderNo",
          align: "center"
        },
        {
          label: "消费者",
          key: "userName",
          align: "center",
          on: row => {
            _this.$router.push({
              path:'/main/activeTask/detail',
              query: {
                id: row.id
              }
            })
          }
        },
        {
          label: "商家名称",
          key: "merchantName",
          align: "center"
        },
        {
          label: "剩余活动额度",
          key: "residueAmount",
          align: "center"
        },
        {
          label: "本次赠品数",
          key: "sumQuantity",
          align: "center",
          on: row => {
            _this.getDetail(row)
          }
        },
        {
          label: "送货门店",
          key: "groupName",
          align: "center"
        },
        {
          label: "收件地址",
          key: "address",
          align: "center"
        },
        {
          label: "提交时间",
          key: "createDate",
          align: "center"
        },
        {
          label: "订单状态",
          key: "state",
          align: "center"
        },
        ],
        exportButtons: [{
          type: "Export",
          url: this.EXport + "/mall-service/v1/activity/task/export",
          listName: '活动任务'
        }],
      },
      //  merchantFlag: false, 
    }
  },
  created() {
    console.log("activeTask-list activated!!");

  },
  activated() {
    console.log("activeTask-list activated!!");
    this.merchantFlag = this.getLoginType('loginType');
    if (this.merchantFlag) {
      this.opt.search.forEach((item, index) => {
        if (item.key == 'merchantId') {
          this.opt.search.splice(index, 1);
        }
      })
      this.opt.columns.forEach((item, index) => {
        if (item.key == 'merchantName') {
          this.opt.columns.splice(index, 1);
        }
      })
    } else {
      this.opt.search.forEach((item, index) => {
        if (item.key == 'merchantId') {
          cabinetUtil.getMerchantList().then(res => {
            item.opt.list = res;
          })
        }
      })
    }
    // this.loginType = this.cache.getLS('loginType');
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        ...opt.searchForm,
        startDate: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
        endDate: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
      }
      this.post('/mall-service/v1/activity/task/page', dto, {
        isUseResponse: true,
      }).then(res => {
        let obj = {
          data: [],
          total: 0,
        }
        obj.data = res.data.data.data;
        obj.total = res.data.data.total;
        if (obj.data) {
          obj.data.forEach(item => {
            item.userName = item.userName ? item.userName : '--';
            item.merchantName = item.merchantName ? item.merchantName : '--';
            item.state = ['未送货', '已送货'][item.state];
          })
        }
        opt.cb(obj)
      });
      if (opt.searchForm.merchantId) {
        this.getStoreList(opt.searchForm.merchantId);
      } else {
        this.getStoreList();
      }
    },
    getDetail(row) {
      this.GivesDataLt = [];
      this.dialogVisibleGives = true;
      this.post("/mall-service/v1/activity/task/get-item-list?id=" + row.id, {
        isUseResponse: true,
      }).then(res => {
        this.GivesDataLt = res;
        this.GivesDataLt.forEach(item => {
          item.auditState = ['未审核', '审核通过', '审核不通过'][item.auditState];
        })
      })
    },
    close() {
      this.dialogVisibleGives = false;
    },
    getStoreList(data) {
      let dto = {
        currentPage: 1,
        size: 99999,
      };
      if (data) {
        dto.merchantId = data
      }
      this.post("/mall-service/v1/counterStores/getAllList", dto, {
        isUseResponse: true,
      }).then(res => {
        let storeList = res.data.data;
        storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
        storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
        this.opt.search.forEach(item => {
          if (item.key == 'groupId') {
            item.opt.list = storeList;
          }
        })
      });
    },
  }
}
</script>
<style lang="scss" scope>
.dialog-footer {
  text-align: center;
  margin-top: 20px;
}

.deviceDetails-table {
  display: flex;
}
</style>
<style lang="scss">
 .app-list .row-nav-vice {
  cursor: pointer;
  border-bottom:none !important;
  color: #30BAC1;
}
</style>