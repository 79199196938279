var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activeTask-list" },
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.givesTitle,
            visible: _vm.dialogVisibleGives,
            "close-on-click-modal": false,
            width: "65%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisibleGives = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "deviceDetails-table" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.GivesDataLt, height: "500" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "商品名称", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "code", label: "商品编号", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "typeName",
                      label: "商品品类",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "quantity",
                      label: "赠品数量",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "costPrice",
                      label: "成本价",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sellPrice",
                      label: "销售价",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "merchantName",
                      label: "发布商家",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "supplierName",
                      label: "供应商名称",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "detail",
                      label: "商品描述",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "pic", label: "商品图片", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("img", {
                              staticStyle: { height: "100px" },
                              attrs: { src: scope.row.pic, alt: "" }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "auditState",
                      label: "商品状态",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.close()
                    }
                  }
                },
                [_vm._v("关闭")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }